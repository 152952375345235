.galeria{
  width: 100%;
  display: flex;
  margin-bottom: 5%;
}

.galeria img{
  display: block;
  cursor: pointer;
}

.container{
  margin: auto;
  width: 90%;
}

.container .selected {
  width: 70%;
  margin: 0 auto;
  border: 4px solid green;
}

.container .imgContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 40px 0 0 0;
}

.container .imgContainer img{
  width: 16.4%;
  margin: 5px 0;
  transition: 0.5s ease-out;
  outline: solid 2px rgb(255, 255, 255);
}

.container .imgContainer img:hover{
  transform: scale(0.8);
  border-radius: 20px;
  transition: 0.5s ease-out;
}

@media only screen and (max-width: 786px){
  .container .imgContainer {
    width: 90%;
    margin: auto;
  }
  .container .imgContainer img{
    width: 31.5%;
    margin: 2px;
  }
  #galeria{
    height: 120px;
  }
}