
nav {
    position: fixed;
    display: flex;
    align-items: center;
    min-height: 8vh;
    justify-content:space-between;
    width: 950px;
    opacity: 1;
    z-index: 1;
    left: 50%;
    transform: translate(-50%);
}
nav img{
    position: relative;
}

nav li {
    list-style: none;
    margin: 10px 0 0 0;
}

nav ul{
    display: flex;
    justify-content:space-evenly;
    width: 40%;
}

.logo{
    width: 75px;
    height: 75px;
}

.icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    left: 50%;
    transform: translateX(-50%);
}
nav a{
    filter: brightness(100%);
    transition: all 0.5s ease;
}
nav a:hover{
    filter: brightness(70%);
    transition: all 0.5s ease;
}

nav p{
    margin-top: 1%;
    font-size: 15px;
    color: black;
}
                        /* PHONE SCREEN NAVIGATION BAR */

@media screen and (max-width:786px) {

    nav {
        display: flex;
        align-items:flex-start;
        width: 100vw;
    }
        nav .logo{
            margin-top: 10px;
            padding-left: 1%;
         }
        nav .ul{
            position: absolute;
            right: 10px;
            width:fit-content;
            padding: 0;
        }
        nav li, nav p{
            display: none;
        }
        nav ul :nth-of-type(2){
            display: flex;
            margin: auto;
        }
        nav a:hover{
            -webkit-filter: brightness(100%);
            transition: all 0.5s ease;
        }
}
