.artykuly {
    display: flex;
    justify-content: space-evenly;
    color: #767676;
}

.image_naszaoferta{
    width: 30vh;
    border-radius: 20px;
    border: 1px black solid
}

.col1{
    width: 30vh;
}

.bordersOferta{
    display: flex;
    flex-direction: row;;
    position: absolute;
}

.borderOferta1{
    border-left: 1px solid #000;
    height: 225px;
    position: absolute;
    margin-top: 50px;
    margin-left: 650px;
    top: 0;
}

.borderOferta2{
    border-left: 1px solid #000;
    height: 225px;
    position: absolute;
    margin-top: 50px;
    margin-left: 350px;
    top: 0;
}

@media only screen and (min-width: 1500px) {
    .borderOferta1{
        margin-left: 340px; 
    }

    .borderOferta2{
        margin-left: 660px
    }
}

@media only screen and (max-width: 768px){
    .artykuly{
        flex-direction: column;
        align-items: center;
        margin-top: -10%;
        width: 100%;
    }
    .borderOferta1{
        display: none;
    }
    .borderOferta2{
        display: none;
    }
    .artykuly li{
        font-size: 15px;
        line-height: 1.4em;
    }
    .artykuly h2{
        margin-top: 2%;
        width:75vw;
    }
    .col1 {
        width:80vw;
    }

    .image_naszaoferta{
        margin-left: 5%;
        width: 70vw;
    }
}