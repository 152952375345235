@font-face {
    font-family: "DIN"; 
    src: url("//db.onlinewebfonts.com/t/3403275a8aeb6fc07122a8dd7f10e0ec.woff") format("woff"); 
    font-weight: normal;
    font-style: normal;
}
#root{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin: auto;
}

html{
    scroll-behavior: smooth;
    overflow-x: hidden;
}

body{
    background-color: #F0F4FF;
    margin: 0px;
    padding: 0px;
    }

main{
    background-color: #f8fafa;
    margin: -54px 0px;
    width: 1000px;
    position:relative;
    left: 50%;
    transform: translate(-50%);
}
section{
    margin: 0;
}

h1 {
    color: #767676;
    font-size: 50px;
    position: relative;
    top: 20px;
}

h1:before {
    content: "";
    display: block;
    width: 24%;
    height: 1px;
    background: #000;
    position: absolute;
    left: 10%;
    top: 50%;
}

h1:after {
    content: "";
    display: block;
    width: 24%;
    height: 1px;
    background: #000;
    position: absolute;
    right: 10%;
    top: 50%;
}

h2{
    font-size: 22px;
}

p{
    font-size: 20px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}
    
p, h1, h2{
    text-align: center;
}

ul{
    align-items: center;
}

li{
    font-size: 15px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    line-height: 1.7em;
}

.section1 {
    height: 156px;
}

a{
    text-decoration: none;
}

span{
    border-bottom: solid 1px;
    border-color: black
}

.center_horizontal{
    margin-left:auto;
    margin-right:auto;
}

@media only screen and (max-width: 768px){
    body {
        overflow-x: hidden !important;
        margin: auto;
    }
    main{
        width:408px;
    }

    h1:before{
        display:none;
    }

    h1:after{
        display:none;
    }
    .mobileIcon{
        height:50px;
        width:50px;
    }
}