video{
    position: flex;
    width: 100%;
    height: 100vh;
    object-fit: cover;
    margin-bottom: 50px;
}

.video a{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    border: 0;
    border-radius: 5px;
    box-shadow: 3.2px 4px 11px 5px rgb(0 0 0 / 60%);
    font-size: 25px;
    background-image: linear-gradient(rgb(214, 226, 215), #AACF38, #AACF38);
    opacity: 0.7;
    padding: 10px 0 5px 0;
    position: absolute;
    left: 41%;
    bottom: 12.5%;
    height: 40px;
    text-align: center;
    color: black;
    width: 261px;
    transition: all 1s ease;
}

.video a:hover{
    cursor:pointer;
    opacity:1;
    z-index: 200;
    transition: all 1s ease;
}


@media only screen and (min-width: 1500px){
.video a{
    left: 43%;
}
}

@media only screen and (max-width: 768px){
    .video{
        display: none;
    }
}