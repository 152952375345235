#onas {
    display: flex;
    flex-direction:column;
    justify-content: center;
}

.section2{
    margin-top: 50px;
    display:none;
    flex-direction: row;
    text-align: center;
    padding: 50px;
}

.section2 p{
    width: 34%;
    margin: auto;
}

.taczka{
    background-image: url(../images/taczka-1.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position:bottom;
    height: 663px;
}

.taczka p{
    color: #E8E6E6;
    position: static;
    padding: 150px;
    font-size: 22px;
    letter-spacing: 0.05em;
    line-height: 1.5em;
    text-shadow: rgb(0 0 0 / 40%) 0px 4px 5px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

#onas img{
    position:relative;
    border-color: rgb(102, 102, 102);
    border: 1px solid ;
    height: 340px;
}

#onas .section2 p{
    font-size: 20px;
    letter-spacing: 0.05em;
    line-height: 1.5em;
    color: #767676;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

@media only screen and (max-width: 768px){
.taczka{
    background-position-x: center;
    padding: 0px;
}
.taczka p{
    font-size: 15px;
    padding: 0px;
    padding-top: 180px;
}
.section2{
    flex-direction: column;
    width: 50vh;
    padding: 0px;
    margin-left: auto;
    font-size: 10px;
}
#onas .section2 img{
    height: 200px;
    width: 300px;
    margin-left: 15px;
}
#onas .section2 p{
    width:90%;
    font-size: 15px;
    padding: 0px;
    margin-right: 60px;
    margin-top: 10px;
}
.section2:nth-last-child(1){
    flex-direction: column-reverse;
    font-size: 10px;
}
.section2:nth-last-child(2){
    margin-top:90px;
}
.zdjecie1{
    margin: auto;
}
}