.sklep img{
    position: absolute;
    width:100px;
    height: 100px;
    border-radius: 50px;
    border-style: solid;
    border-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
}
.sklep{
    position: relative;
    background-image: url(../images/zdjecie3.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 80vh;
}
.sklep img{
    z-index:2;
}

.sklep p{
    position:absolute;
    left: 50%;
    top: 30%;
    transform: translate(-50%);
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 20px;
}

.verdana{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: #767676;
    margin: 70px 0;
}

.sklep .border{
    border-bottom: solid;
    margin: 70;
}

@media only screen and (max-width: 786px){
    .verdana{
        font-size: 16px;
        margin: 0px;
    }
    .sklep{
        background-size:contain;
        margin: 4% 0 0 0;
        height: 273px;
    }
    .sklep img{
        width: 50px;
        height: 50px;
        z-index: 2;
    }
    .sklep p{
        font-size: 15px;
        top: 28%;
    }
}