/* --- KONTAKT section ---*/
.kontakt{
    display: flex;
    flex-direction: row;
    height: 400px;
}

.kontakt h2{
    color: #1A9696;
    font-size: 50px;
    font-family: DIN;
    letter-spacing: 0.1em;
}

.kontakt p{
    color: #767676;
}

.kontakt .border-mid {
    border-left: 3px solid #000;
    height: 310px;
    position: relative;
    left: 50.2%;
    margin-top: 40px;
    margin-left: -3px;
    top: 0;
}
/* --- PHONE SECTION ---*/
.telefon{
    display:flex;
    flex-direction:column;
    width: 50%;
    height: 400px;
    background-color:white;
    margin: 0;
}

.telefon p{
    font-family: verdana,geneva,sans-serif;
    font-size: 30px;
    padding-top: 4%;
}
/* --- WORK TIME SECTION --- */
.praca{
    background: #E8E6E6;
    width: 50%;
}

.godziny{
    padding-top: 25px;
    font-family: verdana,geneva,sans-serif;
    display:flex;
    flex-direction: row;
    justify-content: right;
}

.godziny .dni{
    width:30%;
}

.godziny .czas{
    width:50%;
}

.godziny p{
    text-align: left;
}

#green{
    color: #1A9696;
}
/* --- ADRES section --- */

.adres{
    background-attachment: fixed;
    padding-top: 50px;
}

.background {
    position: relative;
    height: 850px;
    background: url(../images/adres.jpg) no-repeat;
    background-size: 100%;
    background-position: center;
  }

.adres h1 {
    top: -40px;
}

.adres p{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.adres .grey{
    color:#767676;
    letter-spacing: 0.2em;
    line-height: 0.5em;
}

/* ----- MAP ---- */

.mapouter{
    position: relative;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
}

@media only screen and (max-width: 768px){
    .kontakt{
        flex-direction:column;
        max-height:500px;
        width:100%;
    }
    .kontakt h2{
        font-size: 25px;
    }
    .kontakt .border-mid {
        display:none;
        height: 300px;
        margin-top: 0;
    }

    .telefon{
        justify-content: center;
        min-height: 100px;
        width: 100%;
    }
    .telefon h2{
        margin-top: 10px;
        margin-bottom: 0;
    }
    .telefon p{
         margin-top: 5px;
    }
    .praca{
        width:100%;
    }
    .godziny{
        padding: 0px;
    }

    /* --- MAP --- */
    .mapouter{
        width: 90vw;
        height: 50vh;
    }
    .mapouter #gmap_canvas{
        width: 300px;
        height: 300px;
    }
    .adres h1{
        margin-bottom: 0px;
        top: -20px;
    }

    .background{
        padding: 10;
        height: 85vh;
        background-size: 85vh;
    }
    .adres h1 {
        top: -20px;
    }

}